.slideshow-wrap {
    position: relative;
    .caption {
      position: absolute;
      top: 59%;
      left: 10%;
      transform: translateY(-50%);
      z-index: $z-index-slide-caption;
      h2 {
          font-size: $font-size-40;
          color: $color-text-gris;
          text-transform: uppercase;
          font-weight: 500;
          line-height: 1;
          padding-bottom: 50px;
          span {
              font-size: $font-size-50;
              color: $color-white;
          }
          @media screen and (max-width: 1460px){
            padding-bottom: 35px;
            @media screen and (max-width: $size-sm-max){
              padding-bottom: 15px;
            }
          }
      }

      a.burst-slideshow {
          position: relative;
          img {          
            transition: 0.3s;    
              &:nth-child(1) {
                  opacity: 1;
              }
              &:nth-child(2) {
                  position: absolute;
                  opacity: 0;
                  top: 0;
              }
          }
          &:hover {
            transition: 0.3s;    
            img:nth-child(1) {
                opacity: 0;
            }
          }
          &:hover img:nth-child(2) {
              opacity: 1;
          }
          @media screen and (max-width: 1460px){
            img {
                width: 60%;
            }
            @media screen and (max-width: $size-sm-max){
              img {
                  width: 45%;
              }
            }
          }
      }
      @media screen and (max-width: 1200px){
        h2 {
            font-size: 20px;;
            color: $color-text-gris;
            text-transform: uppercase;
            font-weight: 500;
            line-height: 1;
            span {
                font-size: 24px;;
                color: $color-white;
            }
        }
      }
    }
    .slideshow {
      	width: 100%;
      	overflow: hidden;
      	position: relative;
      	.placeholder {
        		width: 100vw;
        		height: auto;
        		display: block;
            @media screen and (max-width: $size-slider-breakpoint){
                height: calc(100vw / 480 * 550);
            }
      	}
        &.mobile {
            display: none;
        }
      	.slide {
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            opacity: 0;
            width: 100vw;
            overflow: hidden;
            position: absolute;
            backface-visibility: hidden;
            transition: 1.5s;

            &_wrapper {
                position: relative;
                .layer-inner {
                    left: 0;
                    right: 0;
                    bottom: 20%;
                    padding: 20px;
                    position: absolute;
                    background: rgba(0,0,0, 0.5);

                    h2 {
                        font-weight: 900;
                        text-align: center;
                        font-size: $font-size-46;
                        color: $color-white;
                        @media screen and (max-width: $size-sm-max) {
                            font-size: 2rem;
                        }
                    }
                }
            }
        		img {
        			 width: 100vw!important;
        			 height: auto;
        			 display: block;
        			 position: relative;
        		}
            &.transiting {
                left: 0;
                right: 0;
                opacity: 1;
                transition: 1.5s;
        		}
        		&.active {
          			right: 0;
                left: 0;
          			z-index: 10;
                opacity: 1;
                transition: 1.5s;
        		}
        	}
        	@media screen and (max-width: 480px) {
              &.mobile {
                  display: block;
              }
              &.ecran {
                  display: none;
              }
        	}
      }
      @media screen and (max-width: $size-sm-max) {
            .caption {
              top: 62%;
            }
        @media screen and (max-width: $size-slider-breakpoint) {
            .caption {
              top: 67%;
                h2 {
                  color: $color-white;
                  text-shadow: 2px 2px 5px $color-black;
                }
                img {
                    width: 30% !important;
                }
            }
        }
      }
}
