.btn {
    background-color: $color-primary;
    color: $color-white;
    border-radius: 0;
    padding: 5px 15px;
    font-size: $font-size-16;
    width: 100%;
    transition: all 0.2s ease-in;
    span.btn-em {
        font-size: $font-size-20;
        text-transform: uppercase;
        font-weight: 600;
    }
    &:hover {
        transition: all 0.2s ease-in;
        font-size: $font-size-16;
        background-color: $color-red-hover;
    }
}
