.module-header {
    position: relative;

    &--image {}

    &--image-mobile {}

    &--caption {
        position: absolute;
        top: 50%;
        width: 100%;
        text-align: center;

        &--title {
            width: 100%;
            font-size: 5vw;
            font-weight: 400;
            padding-left: 10%;
            text-align: left;
            color: $color-white;
            text-shadow: 0 0 10px $color-black;
        }
        &--subtitle {
            width: 100%;
            font-size: 3vw;
            text-align: left;
            font-weight: 400;
            color: #979797;
            padding-left: 10%;
            text-shadow: 0 0 10px $color-black;
        }

    }
}
@media screen and (max-width: $size-md-max) {
    .module-header {
        &--caption {
            // &--title {
            //     font-size: 6vw;
            // }
            // &--subtitle {
            //     font-size: 4vw;
            // }
        }
    }
}
@media screen and (max-width: $size-sm-max) {
    .module-header {
        &--caption {
            // &--title {
            //     font-size: 7vw;
            // }
            // &--subtitle {
            //     font-size: 5vw;
            // }
        }
    }
}
@media screen and (max-width: $size-xs-max) {
    .module-header {
        &--caption {
            &--title {
                font-size: 8vw;
            }
            &--subtitle {
                font-size: 6vw;
                color: $color-white;
            }
        }
    }
}
