// IMPORT ADDONS
@import '../vendors/bootstrap/css/bootstrap';
//@import '../vendors/font-awesome/font-awesome';

/* purgecss start ignore */
// @import '../vendors/owl-carousel2/owl.carousel';
// @import '../vendors/magnific-popup/magnific-popup';
// @import '../vendors/cube-portfolio/cubeportfolio';
@import 'styles/alertify';
/* purgecss end ignore */

// IMPORT CUSTOMIZATION & RESET (order is important)
@import 'core/reset';
@import 'variables';
@import 'core/html';
@import 'utilities/functions';

// *******************
// Load basic classes (besoin de rester ici à la fin)
@import 'styles/fonts'; // utility classes for fonts
@import 'utilities/spacing'; // utility classes for spacing
@import 'utilities/flex'; // utility classes for flex box
@import 'utilities/responsive';
@import 'utilities/animation';

@import 'styles/colors'; // utility classes for colors
@import 'styles/hover';
@import 'styles/typography';
@import 'styles/buttons';

/* GENERAL TEMPLATE */
body {
    background-color: $bg-color-body;

    // to fix parallax bug on iphone...
    .parallax {
        background-attachment: fixed;
        @media screen and (max-width: $size-xs-max) {
            background-attachment: scroll;
        }
    }
}

/** Add this class to any anchor tag so it clear the fixed menu height (used in forms) */
.jumptarget::before {
    content: "";
    display: block;
    height: $size-nav-height; /* fixed header height*/
    margin: -$size-nav-height 0 0; /* negative fixed header height */
}

/** Adds a shadow to the div element **/
.shadow {
    position: relative;

    &::after {
        content: '';
        z-index: 0;
        width: 100%;
        top: 100%;
        height: 40px;
        position: absolute;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    }
}


/*****************************************************/
/* Menu (to use with /modules/module-menu.php)
/* Or /modules/module-menu-centre.php
/* Or /modules/module-menu-etage.php
 */
/*****************************************************/
@import 'modules/menu';
// @import 'menu-centre';
// @import 'menu-etage';


/*****************************************************/
/* Footer (to use with /modules/module-footer.php)
/*****************************************************/
@import 'styles/footer';

/*****************************************************/
/* Sections (to use with /sections/section-*-*.php)
/*****************************************************/
//@import 'sections/section-header-page';

//@import 'sections/section-text-image';

@import 'sections/section-bursts';

/*****************************************************/
/* Modules (to use with /modules/module-*-*.php)
/*****************************************************/
//@import 'modules/module-slideshow';
@import 'modules/module-slider-light';
@import 'modules/module-header';
//@import 'modules/module-soumission';


.container {
    width: 1535px;
    margin: auto;
    @media screen and (max-width: 1550px) {
        width: 90%;
        padding: 0 25px;
        @media screen and (max-width: $size-xs-max) {
            padding: 0;
        }
    }
}

p {
    font-family: $font-family-2;
    font-weight: normal;
    font-size: $font-size-20;
    color: #727272;
    line-height: 1.3;
    margin: 0;
}

/*******************************************************************************
* COMMON TO ALL PAGES
*/

/*******************************************************************************
* ACCUEIL
*******************************************************************************/

#section-image-text {
    background: url("../images/accueil_section02_bg.jpg") no-repeat;
    background-size: cover;
    padding: 75px 0 200px 0;
    .title {
        text-align: center;
        padding-bottom: 125px;
        h3 {
            font-weight: 600;
            font-size: $font-size-40;
            color: #a20000;
            padding-bottom: 5px;
        }

        h4 {
            font-weight: 300;
            font-size: $font-size-40;
            color: $color-white;
        }
        .border {
            background: #a20000;
            display: block;
            width: 145px;
            height: 5px;
            margin: 35px auto 0 auto;
        }
    }
    p {
        color: #c2c2c2;
        text-align: justify;
    }
    .text-center {
        text-align: center;
    }
    p.red {
        color: $color-1;
        font-weight: 700;
    }
    .text-side {
        width: 50%;
        margin-right: 5%;

    }
    .image-side {
        width: 50%;
    }
    @media screen and (max-width: $size-md-max) {
        .container {
            flex-direction: column;
            .text-side {
                width: 100%;
                margin: 0 0 35px 0;
            }
            .image-side {
                width: 75%;
            }
        }
        @media screen and (max-width: $size-sm-max) {
            .container {
                .image-side {
                    width: 100%;
                }
            }
            @media screen and (max-width: 1024px) {
                padding: 75px 0;
                .title {
                    padding-bottom: 50px;
                }
            }
        }
    }
}
#section-title-list {
    background: url("../images/accueil_section03_bg.jpg") no-repeat;
    background-size: cover;
    min-height: 750px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .title {
        text-align: center;
        padding-bottom: 75px;
        h3 {
            font-weight: 600;
            font-size: $font-size-40;
            color: $color-white;
            padding-bottom: 5px;
        }
        h4 {
            font-weight: 300;
            font-size: $font-size-40;
            color: $color-white;
        }
        .border {
            background: #000;
            display: block;
            width: 145px;
            height: 5px;
            margin: 35px auto 0 auto;
        }
    }
    .section-list {
        width: 55%;
        margin-left: 9%;
        ul {
            list-style-image: url(../images/accueil_section03_check.png);
            li {
                font-weight: 600;
                font-size: $font-size-20;
                color: $color-white;
                line-height: 1.5;
                padding-bottom: 10px;
                padding-left: 5px;
            }
        }
        .left-side {
            width: 45%;
        }
    }
    @media screen and (max-width: 1425px) {
        .section-list {
            width: 70%;
        }
        @media screen and (max-width: 1200px) {
            .section-list {
                width: 75%;
            }
            @media screen and (max-width: $size-sm-max) {
                padding: 0 2%;
                .section-list {
                    width: 85%;
                    .left-side {
                        width: 70%;
                    }
                }
                @media screen and (max-width: $size-xs-max) {
                    background: url("../images/accueil_section03_bg_m.jpg") no-repeat;
                    background-size: cover;
                    .section-list {
                        flex-direction: column;
                        margin-left: 0;
                        .left-side {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}

#section-background {
    background: url("../images/accueil_section04_bg.jpg") no-repeat;
    min-height: 900px;
    background-size: cover;
    background-position: center;
    .contenu {
        padding-top: 11%;
        .title {
            text-align: center;
            padding-bottom: 75px;
            width: 40%;
            h3 {
                font-weight: 600;
                font-size: $font-size-40;
                color: #a20000;
                padding-bottom: 5px;
            }
            h4 {
                font-weight: 300;
                font-size: $font-size-40;
                color: $color-white;
            }
            .border {
                background: #a20000;
                display: block;
                width: 145px;
                height: 5px;
                margin: 35px auto 0 auto;
            }
        }
        .text-box {
            width: 45%;
            p {
                color: #c2c2c2;
            }
        }
    }
    @media screen and (max-width: 1550px) {
        .contenu {
            padding-top: 15%;
        }
        @media screen and (max-width: $size-sm-max) {
            .contenu {
                padding-top: 55%;
                .title {
                    padding-bottom: 35px;
                }
            }
            @media screen and (max-width: $size-xs-max) {
                background: #1c1c1c;
                min-height: initial;
                padding: 75px 0;
                .contenu {
                    padding-top: 0;
                    .title {
                        padding-bottom: 35px;
                        width: 100%;
                    }
                    .text-box {
                        width: 100%;
                    }
                }
            }
        }
    }
}

#section-partenaires {
    padding: 100px 0;
    .title {
        text-align: center;
        padding-bottom: 100px;
        h3 {
            font-weight: 600;
            font-size: $font-size-40;
            color: #a20000;
            padding-bottom: 5px;
        }
        h4 {
            font-weight: 300;
            font-size: $font-size-40;
            color: #1c1c1c;
        }
        .border {
            background: #a20000;
            display: block;
            width: 145px;
            height: 5px;
            margin: 35px auto 0 auto;
        }
    }
    .logo-text.flex {
        .center {
            width: 55%;
            padding: 0 50px;
            a.btn-plus {
                background: #a20000;
                display: block;
                width: 230px;
                padding: 15px;
                text-align: center;
                margin: 65px auto 0 auto;
                font-weight: 600;
                font-size: $font-size-18;
                text-transform: uppercase;
                color: $color-white;
                transition: 0.3s;
                &:hover {
                    background: #ee1c25;
                    transition: 0.3s;
                }
            }
        }
    }
    @media screen and (max-width: $size-md-max) {
        .logo-text.flex {
            .center {
                width: 85%;
            }
        }
        @media screen and (max-width: $size-sm-max) {
            .logo-text.flex {
                flex-direction: column;
                .img-responsive {
                    margin: auto;
                }
                .center {
                    width: 100%;
                    padding: 35px 0;
                    text-align: center;
                }
                .title {
                    padding-bottom: 60px;
                }
            }
        }
    }
}
#section-burst {
    background: #1c1c1c;
    padding: 100px 0;
}

#section-footer {
    background: linear-gradient(to right, #1c1c1c 0%, #1c1c1c 10%,#363636 50%, #1c1c1c 90%, #1c1c1c 100%,);
    padding: 20px 10px;
    .container {
    width: 100%;
}
    a {
        .icon {
            position: relative;
            img {
                transition: 0.3s;
                &:nth-child(1) {
                    opacity: 1;
                    transition: 0.3s;
                }
                &:nth-child(2) {
                    position: absolute;
                    opacity: 0;
                    top: 0;
                    transition: 0.3s;
                }
            }
        }
        h4 {
           font-weight: 500;
           font-size: $font-size-16;
           color: #6a6a6a;
        }
        h5 {
            font-weight: 500;
            font-size: $font-size-16;
            color: #980101;
        }
        &:hover {
            img {
                transition: 0.3s;
                &:nth-child(1) {
                    opacity: 0;
                    transition: 0.3s;
                }
                &:nth-child(2) {
                    opacity: 1;
                    transition: 0.3s;
                }
            }
        }
    }
    @media screen and (max-width: $size-xs-max) {
        //height: 80px;
        .container{
            flex-direction: column;
            align-items: center;
            a {
                margin: 10px auto;
            }
        }
    }
}

section.fabrication_burst {
    background-color: $color-black;
    padding: 10px 0;
    .text-side {
        height: 100%;
        h3 {
            color: $color-1;
            font-size: $font-size-26;
            font-weight: 400;
            line-height: 1.3;
        }
        h4 {
            color: $color-white;
            font-size: $font-size-26;
            font-weight: 400;
            line-height: 1.3;
            span {
                text-transform: uppercase;
            }
        }
    }
}

section.fabrication_liste {
    background-color: $color-white;
    min-height: 600px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 30px 10px;
    .title {
        text-align: center;
        padding-bottom: 75px;
        h3 {
            font-weight: 600;
            font-size: $font-size-40;
            color: $color-1;
            padding-bottom: 5px;
        }

        .border {
            background: $color-1;
            display: block;
            width: 145px;
            height: 5px;
            margin: 35px auto 0 auto;
        }
    }
    .section-list {
        width: 90%;
        //margin-left: 9%;
        ul {
            list-style-image: url(../images/fabrication_section05_crochet.png);
            li {
                font-weight: 600;
                font-size: $font-size-20;
                color: $color-black;
                line-height: 1.5;
                padding-bottom: 10px;
                padding-left: 5px;
            }
        }
    }
    @media screen and (max-width: 1200px) {

        @media screen and (max-width: $size-sm-max) {
            //padding: 0 2%;
            .title {
                h3 {font-size: 24px;}
              }
            .section-list {
                flex-direction: column;
                align-items: center;
                justify-content: center;
                margin-left: 0;
                .left-side, .center-side, .right-side {
                    width: 210px;;
                }
            }

        }
    }
}




section.section-liste-img-reparation {
    background-color: $color-black;
    padding: 75px 0 0 0;
    .title {
        text-align: center;
        padding-bottom: 125px;
        h3 {
            font-weight: 600;
            font-size: $font-size-40;
            color: #a20000;
            padding-bottom: 5px;
        }

        h4 {
            font-weight: 300;
            font-size: $font-size-40;
            color: $color-white;
        }
        .border {
            background: #a20000;
            display: block;
            width: 145px;
            height: 5px;
            margin: 35px auto 0 auto;
        }
    }
    p {
        color: #c2c2c2;
        text-align: justify;
    }
    ul {
        list-style-image: url(../images/accueil_section03_check.png);
        padding-left: 35px;
        li {
            font-weight: 600;
            font-size: $font-size-18;
            color: $color-1;
            line-height: 1.3;
            padding-bottom: 10px;
            padding-left: 5px;
            text-transform: inherit;
        }
    }
    .text-center {
        text-align: center;
    }
    p.red {
        color: $color-1;
        font-weight: 700;
    }

    .image-side {
        //width: 50%;
    }
    a.photo-burst {
        position: relative;
        .img-responsive {
            margin: auto;
            padding-top: 35px;
        }
        img {
            transition: 0.3s;
            &:nth-child(1) {
                opacity: 1;
            }
            &:nth-child(2) {
                position: absolute;
                opacity: 0;
                top: 0;
                right: 706px;
            }
        }
        &:hover {
            img {
                &:nth-child(1) {
                    opacity: 0;
                }
                &:nth-child(2) {
                    opacity: 1;
                }
            }
        }
        @media screen and (max-width: $size-xs-max) {
            .img-responsive {
                width: 80%;
            }
        }
    }
    @media screen and (max-width: 1024px) {
        padding: 75px 0;
        .title {
            padding-bottom: 50px;
        }
        .container {
            flex-direction: column;
            .text-side {
                width: 100%;
                margin: 0 0 35px 0;
            }
            .image-side {
                width: 100%;
                margin-left: 0;
                .img-responsive {
                    margin: auto;
                }
            }
        }
    }
}

section.section-liste-img-reparation.second {
    padding: 75px 0;
    .title {
        padding-bottom: 75px;
    }
}

section.partenaires_logos {
    background-image: url('../images/partenaires_section02_bg.jpg');
    padding: 100px 0;
    .title {
        text-align: center;
        padding-bottom: 50px;
        h3 {
            font-weight: 600;
            font-size: $font-size-40;
            color: $color-white;
        }

    }
    div.logos_box {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
        grid-gap: 20px;
        align-items: stretch;
        max-width: 1050px;

        margin: auto;
        img {
          margin: auto;
        }
    }
    @media screen and (max-width: $size-xs-max){
      background-image: url('../images/partenaires_section02_bg_m.jpg');
    }
}



section.realisations {
    background-color: $color-black;
    padding: 50px 5%;
    p {
        color: $color-white;
        font-weight: 400;
        font-size: 1.1rem;
        margin: 0;
        text-align: center;
        line-height: 1.5;
    }
    .masonry {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        column-gap: 16px;
        @media (min-width: 1200px) {
        }
        @media (max-width: 992px) {
          grid-template-columns: repeat(2, 1fr);
        }
        @media (max-width: $size-xs-max) {grid-template-columns: repeat(1, 1fr);}
        .grid {
            display: inline-block;
            margin-bottom: 16px;
            position: relative;

            img {
                width: 100%;
            }
            &__image {
              position: relative;
                &::after {
                    content:'';
                    position:absolute;
                    top:0;
                    right:0;
                    bottom:0;
                    left:0;
                    background-image: url('../images/realisations_section01_plus.png');
                    background-position: center;
                    background-size: auto;
                    background-repeat: no-repeat;
                    opacity: 0;
                    transition: .3s;
                    background-color: rgba(162, 0, 0, .66);
                }
            }
            &__text {
                &_title {
                    font-size: 26px;
                    font-weight:400;
                    color: $color-white;
                    transition: .3s;
                    margin: 0px 0px 10px 0px;
                }
                &_border {
                    width: 130px;
                    height: 5px;
                    background: $color-1;
                    margin-bottom: 20px;
                }
                &__description {
                    font-size: 14px;
                    font-weight:300;
                }
            }
            &:hover {
                .grid__image {
                  &::after {
                      opacity: 1;
                      transition: .3s;
                  }
                }
                .grid__text {
                    .grid__text_title {
                        color: $color-1;
                        transition: .3s;
                    }
                }
            }
        }
    }
}

/*******************************************************************************
* COORDONNÉES
*******************************************************************************/

#section-coordonnees {
    padding: 100px 5%;
    background: #000;
    h3#firstHeading {
        color: $color-1!important;
        font-size: 24px;
    }
    a#googleLink {
        color: $color-1;
        &:focus {
            color: $color-1;
            outline:none;
            outline-offset:0;
        }
        &:hover {
            color: $color-black;
        }
    }

    .container {
        width: 1300px;
        .title {
            padding-bottom: 50px;
            h3 {
                font-weight: 500;
                font-size: $font-size-24;
                text-transform: initial;
                color: $color-white;
                padding-bottom: 5px;
            }
            .border {
              width: 130px;
              height: 5px;
              background: $color-1;
              margin-bottom: 20px;
            }
            p {
                color: $color-white;
                font-weight: 400;
            }
        }
        .section-info.flex {
            .left-side {
                width: 50%;
            }
            .right-side {
                width: 50%;
            }
            h3 {
                font-weight: 500;
                font-size: $font-size-30;
                color: $color-white;
                text-transform: initial;
            }
        }
        .section-heures-map {
            padding-top: 50px;
            .left-side {
                width: 50%;
                margin-right: 50px;
                .info {
                    padding-top: 12px;
                }
            }
            .right-side {
                width: 60%;
            }
            h4 {
                font-weight: 500;
                font-size: $font-size-24;
                color: $color-white;
            }
        }
    }
    @media screen and (max-width: 1024px) {
        .container {
            width: 100%;
        }
        @media screen and (max-width: $size-sm-max) {
            .container {
                .section-heures-map {

                    h4 {
                        font-size: 14px;
                    }
                }
            }
            @media screen and (max-width: $size-xs-max) {
                padding: 65px 5%;
                .container {
                    padding: 0;
                    .section-info {
                        flex-direction: column;
                        .left-side {
                            width: 100%!important;
                            margin-bottom: 25px;
                        }
                        .right-side {
                            width: 100%!important;
                        }
                        .icon{
                            width: 40px;
                        }
                        .info h3 {
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }
}


section#section-form-emploi {
    padding: 100px 5% 25px 5%;
    background-color: #1c1c1c;

    .label-wrapper {
        width: 150px;
    }
    label.control-label {
        color: $color-white;
        font-size: 18px;
        font-weight: 400;
    }
    .clear-both {
        clear: both;
    }
    @media screen and (max-width: $size-xs-max){
        .items-baseline {
            flex-direction: column;
            .label-wrapper, .flex-auto {
                width: 100%;
            }
        }
        @media screen and (max-width: $size-slider-breakpoint){
            .bouton {
                width: 200px;
                margin-top: 15px;
            }
        }
    }
}
/*******************************************************************************
* PAGE X
*/

.pageX {
    section.header {

    }
}


/*******************************************************************************
* MOBILE OVERRIDES FOR ALL SECTIONS
*******************************************************************************/
@media screen and (max-width: $size-md-max) {
    /*******************************************************************************
    * COMMON TO ALL PAGES
    */

    /*******************************************************************************
    * X PAGE OVERRIDES
    */

}

@media screen and (max-width: $size-sm-max) {
    /*******************************************************************************
    * COMMON TO ALL PAGES
    */
    section.header {

    }

    /*******************************************************************************
    * X PAGE OVERRIDES
    */

}


@media screen and (max-width: $size-xs-max) {
    /*******************************************************************************
    * COMMON TO ALL PAGES
    */
    section.header {

    }

    /*******************************************************************************
    * X PAGE OVERRIDES
    */
}
