
/** to add hover effect on icon **/
.filter-hover {filter: invert(38%) sepia(97%) saturate(7351%) hue-rotate(348deg) brightness(95%) contrast(96%);}
.filter-black {filter: none;}
.filter-normal {filter: invert(13%) sepia(85%) saturate(4934%) hue-rotate(342deg) brightness(85%) contrast(94%);}

.icon {
    a {
        background-color: inherit;
        position: relative;
        .overlay {
            width: 100%;
            height: 100%;
            opacity: 0;
            visibility: hidden;
            position: absolute;
            top:0;
            background-color: $bg-color-nav-highlight;
            mix-blend-mode: multiply;
            transition: all 0.2s ease-in;
            // for IE explorer fallback
            @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                background: transparentize($color-black, 0.5);
            }
            // For Edge fallback
            @supports (-ms-ime-align:auto) {
                background: transparentize($color-black, 0.5);
            }
        }
        &:hover {
            .overlay {
                opacity: 1;
                visibility: visible;
                transition: all 0.2s ease-out;
            }
        }
    }
}
