.bursts {
    width: 100%;
    .bursts-container {
        @extend .flex;
        .burst {
            //flex: 1;
            position: relative;
            .text-container, .overlay {
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
            }
            .text-container {
                padding-top: 24%;
                padding-left: 50%;
                .border {
                    width: 100%;
                    height: 100%;
                    @extend .flex;
                    @extend .flex-column;
                    @extend .justify-center;
                    @extend .items-center;
                    transition: all 0.2s ease-out; // OUT effect
                }
            }
            .text-container.right {
                padding-top: 17%;
            }
            .overlay {
                opacity: 0;
                transition: all 0.7s ease-out; // OUT effect
            }
            h3 {
                font-size: $font-size-30;
                font-weight: 300;
                text-transform: initial;
                color: $color-white;
            }
            h4 {
                font-size: $font-size-30;
                font-weight: 600;
                text-transform: uppercase;
                color: #a20000;
            }
        }
        a:hover .burst {
            .overlay {
                opacity: 1;
                transition: all 0.3s ease; // IN effect
            }
        }
    }
}
@media screen and (max-width: 1550px) {
    .bursts {
            .bursts-container {
                .burst {
                    .text-container {
                        padding-top: 19%;
                    }
                    .text-container.right {
                        padding-top: 12%;
                    }
                    h4, h3 {
                        font-size: 1.4rem;
                    }
                }
            }
        }
    @media screen and (max-width: 1525px) {
        .bursts {
            .bursts-container {
                .burst {
                    h4, h3 {
                        font-size: 1.3rem;
                    }
                }
            }
        }
        @media screen and (max-width: 1430px) {
            .bursts {
                .bursts-container {
                    .burst {
                        h4, h3 {
                            font-size: 1.15rem;
                        }
                    }
                }
            }
            @media screen and (max-width: 1235px) {
                .bursts {
                    .bursts-container {
                        .burst {
                            .text-container {
                                padding-top: 22%;
                            }
                            .text-container.right {
                                padding-top: 15%;
                            }
                        }
                    }
                }
                @media screen and (max-width: 1160px) {
                    .bursts {
                        .bursts-container {
                            .burst {
                                h4, h3 {
                                    font-size: 1rem;
                                }
                            }
                        }
                    }
                    @media screen and (max-width: $size-sm-max) {
                        .bursts {
                            .bursts-container {
                                flex-direction: column;
                                a.px5 {
                                    padding: 0;
                                    margin-left: -6px;
                                }
                                .burst {
                                    h4, h3 {
                                        font-size: 1.5rem;
                                    }
                                    .text-container {
                                        padding-top: 21%;
                                    }
                                    .text-container.right {
                                        padding-top: 16%;
                                    }
                                    .img-responsive {
                                        margin: auto;
                                    }
                                }
                            }
                        }
                        @media screen and (max-width: 360px) {
                            .bursts {
                                .bursts-container {
                                    .burst {
                                        h4, h3 {
                                            font-size: 1.4rem;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
